<template>
  <div>
    <v-row>
<!--      <v-col style="max-width: 130px">
        &lt;!&ndash; <defaultFieldTypes :fieldAttributes="result.valueLine.n_recurring" field="n_recurring":value="fieldAttributes"></defaultFieldTypes> &ndash;&gt;
       <primaryTextfield
          type="number"
          class="recurring "
          :value="recurring"
          help-data="Specify how many times the test will be executed."
          name="n_recurring"
          label="Iteration"
          style="width: 100px"
          min="1"
          v-model="recurring"
          :fieldAttrInput="{ disabled: disabled,'class':'number','max':'999999' }"
        ></primaryTextfield>
      </v-col>-->

      <v-col v-show="showRec" class="mt-2" style="max-width: 185px">{{
        language.componentLabel.labelPauseBI
      }}</v-col>
      <v-col v-show="showRec" style="max-width: 150px">
        <primaryTextfield
          name="n_recurringInterval"
          label="Seconds"
          type="number"
          class="recurring_interval "
          :value="interval"
          help-data="Specify how long the test will pause between the iterations."
          style="width: 100px"
          :fieldAttrInput="{ disabled: disabled,'class':'number','max':'999999' }"
        ></primaryTextfield>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </div>
</template>
<script>
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
export default {
  components: {
    primaryTextfield,
  },
  name: "testcaseIterator",
  data: function () {
    return {
      // interval: this.getInterval(),
      // recurring: this.getRecurring(),
      // showRec: false,
    };
  },

  props: ["result", "valueLine"],

  computed: {
    disabled() {
      let disabled = false;
      if (this.valueLine.subscriberType == "explorer") {
        disabled = true;
      }
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
    interval: {
      get() {
        // console.log("getInterval", this.result);
        if (
          typeof this.result.valueLine.n_recurringInterval != "undefined" &&
          this.result.valueLine.n_recurringInterval != ""
        ) {
          return this.result.valueLine.n_recurringInterval;
        } else {
          return 0;
        }
      },
      set(newVal) {
        this.$emit("field-value-changed", "n_recurringInterval", newVal);
      },
    },
    recurring: {
      get() {
        if (
          typeof this.result.valueLine.n_recurring != "undefined" &&
          this.result.valueLine.n_recurring != ""
        ) {
          return this.result.valueLine.n_recurring;
        } else {
          return 0;
        }
      },
      set(newVal) {
        this.$emit("field-value-changed", "n_recurring", newVal);
      },
    },

    showRec: function () {
      let val = this.recurring;
      let showRec;
      if (val > 1) {
        showRec = true;
      } else {
        showRec = false;
      }
      return showRec;
    },
  },
  /* $( document ).ready(function() {
    if($('input[name="n_recurring"]').val()>1){
      $('.recurringLabel').show()
     }
    $("input[name='n_recurring']").on("change paste keyup", function() {
       if($(this).val()>1){
        $('.recurringLabel').show()
      }else {
        $('.recurringLabel').hide()
       }
    });

  });*/
};
</script>